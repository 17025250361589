<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="() => $router.back()">
            <ion-icon :icon="chevronBackOutline"> </ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Přihlaste se do socialní sítě</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-back-button :icon="chevronBackCircleOutline">xxx</ion-back-button>
      <p>Přihlašovací kód vám sdělí učitel.</p>
      <div class="gray-input">
        <ion-label position="stacked">Přihlašovací kód</ion-label>
        <ion-input v-model="code" :autofocus="true" type="number"></ion-input>
      </div>
      <ion-button
        color="primary"
        :disabled="!canContinue"
        expand="block"
        @click="enter()"
        >Přihlásit se</ion-button
      >
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonLabel,
  IonInput,
  IonContent,
  IonButton,
  IonBackButton,
  toastController,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonIcon,
} from "@ionic/vue";
import { chevronBackCircleOutline, chevronBackOutline } from "ionicons/icons";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      canContinue: false,
      code: "",
    };
  },
  components: {
    IonPage,
    IonLabel,
    IonInput,
    IonContent,
    IonButton,
    IonBackButton,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonIcon,
  },
  watch: {
    code(value) {
      this.code = value;
      if (this.code.length >= 4) {
        this.canContinue = true;
      } else {
        this.canContinue = false;
      }
    },
  },
  setup() {
    return {
      chevronBackCircleOutline,
      chevronBackOutline,
    };
  },
  methods: {
    ...mapActions({
      enterNetwork: "social/enterNetwork",
      connectToNetworkChannel: "websocket/connectToNetworkChannel",
      connectToChatChannel: "websocket/connectToChatChannel",
    }),
    async openToast(msg, type, position) {
      const toast = await toastController.create({
        message: msg,
        duration: 2000,
        color: type,
        position: position,
      });
      return toast.present();
    },
    enter() {
      const codeEntered = this.code;
      let self = this;
      console.log(codeEntered);
      this.enterNetwork({ code: codeEntered })
        .then((res) => {
          let networkId = res;
          console.log("enterNetwork", res);
          self.connectToNetworkChannel({ id: networkId });
          self.connectToChatChannel({ id: networkId });
          self.$router.push(`/tabs/social/${networkId}`);
        })
        .catch((err) => {
          console.log("ERR", err);
          self.openToast(err, "danger", "top");
        });
    },
  },
};
</script>

<style scoped>
.gray-input {
  background: var(--ion-color-step-150);
  border-radius: 10px;
  padding: 12px 16px;
  margin-bottom: 16px;
}
ion-input {
  --padding-bottom: 0px;
  --padding-top: 0px;
}
ion-content {
  --ion-background-color: var(--ion-card-background);
  --background: var(--ion-card-background);
  background: var(--background);
}
</style>
